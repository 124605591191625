import * as React from "react";
import { Link, graphql } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import PageHero from "../components/page-hero";
import Fader from "../helper/Fader";

// let articleCount = 0;
// const amountPerPage = 9;

// const PaginationLinks = props => {
//   const { amount, paginateClick, page } = props;
//   let i;
//   let elem = [];
//   for (i = 0; i < Math.ceil(amount / amountPerPage); i++) {
//     let j = i;
//     elem.push(
//       <a
//         key={i}
//         href="#news"
//         className={
//           i === page ? "paginate-button h4 -active" : "paginate-button h4"
//         }
//         onClick={e => paginateClick(e, j)}
//       >
//         {i + 1}
//       </a>
//     );
//   }
//   return elem;
// };

// // const counter = (news, categoryId) => {
// //   let counterValue = 0;
// //   news.forEach(article => {
// //     let include = false;
// //     if (article.categories[0]) {
// //       article.categories.forEach(category => {
// //         if (
// //           category.id === categoryId ||
// //           (categoryId === "all" && include !== true)
// //         ) {
// //           include = true;
// //         }
// //       });
// //     } else if (!article.categories[0] && categoryId === "all") {
// //       include = true;
// //     }
// //     if (include === true) {
// //       counterValue++;
// //     }
// //   });
// //   return counterValue;
// // };

// // const ArticlesNav = ({ id, categories, handleClick }) => {
// //   return (
// //     <section className="article-nav" id="news">
// //       {/* MOBILE */}
// //       <select
// //         className="category-selector"
// //         title="Category Selector"
// //         value={id}
// //         onChange={e => handleClick(e, e.target.value)}
// //       >
// //         <option value="all">All</option>
// //         {categories.map((category, i) => {
// //           return (
// //             <option key={i} value={category.node.id}>
// //               {category.node.title}
// //             </option>
// //           );
// //         })}
// //       </select>

// //       {/* DESKTOP */}
// //       <a
// //         className={id === "all" ? "category-button -active" : "category-button"}
// //         href="#"
// //         onClick={e => handleClick(e, "all")}
// //       >
// //         All
// //       </a>
// //       {categories.map((category, i) => {
// //         return (
// //           <a
// //             key={i}
// //             className={
// //               id === category.node.id
// //                 ? "category-button -active"
// //                 : "category-button"
// //             }
// //             href="#"
// //             onClick={e => handleClick(e, category.node.id)}
// //           >
// //             {category.node.title}
// //           </a>
// //         );
// //       })}
// //     </section>
// //   );
// // };

export const query = graphql`
  {
    articles: allSanityNews(
      sort: { fields: publishedAt, order: DESC }
      filter: { slug: { current: { ne: null } } }
      limit: 9
    ) {
      nodes {
        id
        title
        slug {
          current
        }
        categories {
          id
        }
        authors {
          fullName
        }
      }
    }
    page: allSanityPage(filter: { slug: { current: { eq: "news" } } }) {
      edges {
        node {
          id
          pageSEO {
            metaDescription
            metaImage {
              asset {
                url
              }
            }
            metaTitle
          }
          title
          backgroundImage {
            asset {
              fixed(width: 1600, height: 900) {
                ...GatsbySanityImageFixed_withWebp_noBase64
              }
            }
          }
          _rawIntro(resolveReferences: { maxDepth: 8 })
        }
      }
    }
    categories: allSanityNewsCategory(sort: { fields: title }) {
      edges {
        node {
          id
          slug {
            current
          }
          title
        }
      }
    }
  }
`;

class News extends React.Component {
  constructor(props) {
    super(props);
    // this.handleClick = this.handleClick.bind(this);
    // this.paginateClick = this.paginateClick.bind(this);
    this.state = {
      categoryId: "all",
      rangeMin: 0,
      // rangeMax: amountPerPage,
      amount: props.data.articles.nodes.length,
      page: 0,
    };
  }

  // handleClick(e, id) {
  //   e.preventDefault();
  //   let container = document.getElementsByClassName("page-body-inner")[0];
  //   let countValue = counter(this.props.data.articles.nodes, id);
  //   container.classList.add("-hidden");
  //   setTimeout(() => {
  //     container.classList.remove("-hidden");
  //     this.setState({
  //       categoryId: id,
  //       amount: countValue,
  //       rangeMin: 0,
  //       rangeMax: amountPerPage,
  //       page: 0,
  //     });
  //   }, 500);
  // }

  // paginateClick(e, page) {
  //   let container = document.getElementsByClassName("page-body-inner")[0];
  //   container.classList.add("-hidden");
  //   setTimeout(() => {
  //     container.classList.remove("-hidden");
  //     this.setState({
  //       rangeMin: amountPerPage * page === 0 ? 1 : amountPerPage * page + 1,
  //       rangeMax: amountPerPage * (page + 1),
  //       page: page,
  //     });
  //   }, 500);
  // }

  render() {
    const news = this.props.data.articles.nodes;
    // const categories = this.props.data.categories.edges;
    const page = this.props.data.page.edges[0].node;

    return (
      <Layout
        className="page-news-index-container"
        backgroundImage={page.backgroundImage?.asset?.fixed}
      >
        <SEO
          title={(page.pageSEO && page.pageSEO.metaTitle) || page.title}
          description={page.pageSEO && page.pageSEO.metaDescription}
          image={page.pageSEO && page.pageSEO.metaImage}
        />
        <PageHero
          title={page.title}
          intro={page._rawIntro}
          // content={
          //   <ArticlesNav
          //     id={this.state.categoryId}
          //     categories={categories}
          //     handleClick={this.handleClick}
          //   />
          // }
        />
        <Fader>
          <div className="articles-index page-body">
            <div className="page-body-inner">
              <div className="articles-container">
                {news.map((article, i) => {
                  // let include = false;
                  // if (i === 0) {
                  //   articleCount = 0;
                  // }
                  // if (article.categories[0]) {
                  //   article.categories.forEach(category => {
                  //     if (
                  //       category.id === this.state.categoryId ||
                  //       (this.state.categoryId === "all" && include !== true)
                  //     ) {
                  //       include = true;
                  //       articleCount++;
                  //       return "";
                  //     } else {
                  //       return "";
                  //     }
                  //   });
                  // } else if (
                  //   !article.categories[0] &&
                  //   this.state.categoryId === "all"
                  // ) {
                  //   include = true;
                  //   articleCount++;
                  // }
                  // if (
                  //   include === true &&
                  //   articleCount >= this.state.rangeMin &&
                  //   articleCount <= this.state.rangeMax
                  // ) {
                  return (
                    <Link
                      to={`/news/${article.slug.current}`}
                      key={i}
                      className="article-tile"
                    >
                      <h3>{article.title}</h3>
                      {article.authors && (
                        <p className="caption">
                          {article.authors.map((author, k) => {
                            if (i === 0) {
                              return author.fullName;
                            } else if (k === 1) {
                              return `, ${author.fullName}`;
                            } else if (k === 2) {
                              return "...";
                            } else {
                              return "";
                            }
                          })}
                        </p>
                      )}
                    </Link>
                  );
                  // } else {
                  //   return "";
                  // }
                })}
              </div>
              {/* <div className="pagination-container">
                <PaginationLinks
                  amount={this.state.amount}
                  paginateClick={this.paginateClick}
                  page={this.state.page}
                />
              </div> */}
            </div>
          </div>
        </Fader>
      </Layout>
    );
  }
}

export default News;
